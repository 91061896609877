<ng-template #pocision let-offcanvas>
    <div class="offcanvas-header">
        <div class=" w-100">
            <div class="col-12 col-md-6 offset-md-3 ">
                <div class="row">
                    <div class="col-10">
                        <div class="text-capitalize h4 text-secondary mt-2 ">
                            Consulta cupo
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
    <div class="offcanvas-body">
        <div class="row">
            <div class="col-12 col-md-4 offset-md-4 ">
                <ng-container *ngIf="searchPerson ">




                    <div class="text-center mb-1">
                        <div class="text-capitalize h4 text-dark">{{searchPerson?.name}} {{searchPerson?.lastName}}</div>
                        <div> {{identityResponse}}</div>
                    </div>

                    <div class="card mt-5" >
                        <div class="card-body">


                        <div class="row mt-2">
                            <div class="col-6">
                                <div class="small text-secondary">Cupo asignado</div>
                                <h5 class="fw-bolder text-dark">{{searchPerson?.current_quota? searchPerson?.current_quota : base_amount }} USD

                                </h5>
                            </div>

                            <div class="col-6 text-end ">
                                <div class="small text-secondary">Disponible</div>
                                <div class="text-dark">{{searchPerson?.quota | currency:'USD':'': '1.2-2' }} USD
                                </div>
                            </div>
                            <div class="text-end" *ngIf="person?.person?.quota_active">
                                <i class="fas fa-pencil-alt text-primary cursor-pounter" (click)="edit_quote = !edit_quote" *ngIf="!edit_quote"></i>
                                <i class="fas fa-times text-primary cursor-pounter" (click)="edit_quote = !edit_quote" *ngIf="edit_quote"></i>


                            </div>

                            <div *ngIf="edit_quote && person?.person?.quota_active" >

                        <div class="d-flex justify-content-between mt-4">

                            <div class="input-group">

                                <div class="form-floating ">
                                    <input type="number" [(ngModel)]="new_quote" class="form-control " id="contact" placeholder="******">
                                    <label for="contact" class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span >Nuevo Cupo</span>
                                    </label>
                                </div>
                                <button (click)="change_quote()" [disabled]="!new_quote || submit_disabled_quote" class="btn btn-primary">
                                    Cambiar
                                    <span *ngIf="submit_disabled_quote">
                                    <i class="fa  fa-spinner fa-spin"></i>
                                    </span>
                                </button>

                            </div>

</div>
                            </div>

                         <!--
                            <div class="col-6">
                                <div class="small text-secondary">Compras activas</div>
                                <div (click)="offcanvasServiceClose()" [routerLink]="['/console/person/financial-payments/',searchPerson?.code]" class=" text-dark">
                                    <a class="text-primary">{{countInvoice}}</a> </div>
                            </div>
                        -->



                        </div>
                    </div>
                </div>

                <div class="card mt-5" >
                    <div class="card-body">

                        <div class="row mt-2">

                            <div class="col-12 " *ngIf="_auth.IsPermitid(['OWNER'])">
                                <div class="d-flex justify-content-between">

                                    <div>
                                        <div class=" fw-bold ">
                                           Estatus del cupo
                                            <span class="text-secondary small ">({{person?.person?.quota_active?"Activo":"Inactivo"}})</span>

                                        </div>
                                    </div>

                                    <div>
                                        <ng-toggle (change)="toogleStatus()" [(ngModel)]="usQuote" [value]="true" [color]="{
                                            checked: '#01abce',
                                            unchecked: '#dcdcdc'
                                        }"></ng-toggle>

                                    </div>
                                </div>

                            </div>


                            <div class="col-12 mt-3" *ngIf="!_auth.IsPermitid(['OWNER'])">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <div class=" fw-normal ">
                                           Estatus del cupo
                                        </div>
                                    </div>

                                    <div class="ms-auto">
                                        <span class="text-primary ">({{person?.person?.quota_active?"Activo":"Inactivo"}})</span>

                                    </div>


                                </div>
                            </div>


                            <ng-container *ngIf="person?.person?.ejecutive_change_status_date_quota; else automaticData">
                              <div class="col-6 mt-3">
                                  <div class="small text-secondary">Última actualización</div>
                                  <div class="text-dark">{{person?.person?.ejecutive_change_status_date_quota | date: 'dd/MM/yyyy h:mm:ss a'}} </div>
                              </div>

                              <div class="col-6 text-end mt-3">
                                  <div class="small text-secondary">Ejecutivo</div>
                                  <div class="text-dark">
                                      {{person?.person?.ejecutive_change_status_quota.name}} {{person?.person?.ejecutive_change_status_quota.lastName}}
                                  </div>
                              </div>
                          </ng-container>

                          <ng-template #automaticData>
                              <div class="col-6 mt-3" *ngIf="person?.person?.automatic">
                                  <div class="small text-secondary" *ngIf="person?.person?.automatic_change_status_date_quota">Última actualización</div>
                                  <div class="text-dark">{{person?.person?.automatic_change_status_date_quota | date: 'dd/MM/yyyy h:mm:ss a'}} </div>
                              </div>

                              <div class="col-6 text-end mt-3" *ngIf="person?.person?.automatic">
                                  <div class="small text-secondary">Responsable</div>
                                  <div class="text-dark">Automático</div>
                              </div>
                          </ng-template>

                            <div class="col-12" *ngIf="person?.person?.params?.status_quota?.current?.description"> <div class="small text-secondary">Comentario:</div> <div class="text-dark">{{person?.person?.params?.status_quota?.current?.description }}</div> </div>


                            <div class="col-6  mt-3" *ngIf="person?.person?.payment_fee">
                                        <div class="small text-secondary">Pago de reactivación</div>

                                        <div class="small ">
                                            <span>
                                                <div class="text-primary small cursor-pointer" (click)="openBottom(payment,{  person_code: person?.person?.code, code_payment:  person?.person?.payment_fee?.code })">  {{ person?.person?.payment_fee?.code  }}</div>
                                            </span>
                                        </div>


                            </div>








                            </div>
                            </div>
                        </div>

                        <div class="card mt-5" >
                            <div class="card-body">

                                <div class="row mt-2">

                                    <div class="col-12 " *ngIf="_auth.IsPermitid(['OWNER'])">
                                        <div class="d-flex justify-content-between">

                                            <div>
                                                <div class=" fw-bold ">
                                                   Cupo habilitado por el cliente
                                                    <span class="text-secondary small ">({{person?.person?.quota_client_active?"Si":"No"}})</span>

                                                </div>
                                            </div>

                                            <div>
                                                <ng-toggle (change)="toogleStatusClient()" [(ngModel)]="usQuoteClien" [value]="true" [color]="{
                                                    checked: '#01abce',
                                                    unchecked: '#dcdcdc'
                                                }"></ng-toggle>

                                            </div>
                                        </div>

                                    </div>


                                    <div class="col-12 mt-3" *ngIf="!_auth.IsPermitid(['OWNER'])">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <div class=" fw-normal ">
                                                   Estatus del cupo
                                                </div>
                                            </div>

                                            <div class="ms-auto">
                                                <span class="text-primary ">({{person?.person?.quota_active?"Activo":"Inactivo"}})</span>

                                            </div>

                                        </div>

                                    </div>

                                    <div class="col-6  mt-3" *ngIf="person?.person?.ejecutive_quota_client_active">
                                        <div class="small text-secondary">Última actualización</div>
                                        <div class="text-dark">{{person?.person?.date_quota_client_active | date: 'dd/MM/yyyy h:mm:ss a'  }} </div>
                                    </div>

                                    <div class="col-6 text-end mt-3" *ngIf="person?.person?.ejecutive_quota_client_active">
                                        <div class="small text-secondary">Responsable</div>
                                        <div class="text-dark">

                                            {{person?.person?.ejecutive_quota_client_active.name}} {{person?.person?.ejecutive_quota_client_active.lastName}}

                                        </div>
                                    </div>

                                    <div class="col-6  mt-3" *ngIf="person?.payment_fee?.code">
                                        <div class="small text-secondary">Payment</div>
                                        <div class="text-dark">{{ person?.payment_fee?.code  }} </div>
                                    </div>






                                    </div>
                                    </div>
                                </div>

                    <!--  <pre>{{ person?. person | json }}</pre> -->

                </ng-container>


            </div>
        </div>

    </div>
</ng-template>

<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12 col-md-8 offset-md-2">

            <h5 class="mb-1 text-secondary  mb-4 text-center"> <i class="  fas fa-user"></i> Perfil
            </h5>



            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

            </div>


            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img class="rounded-circle  " [src]="sanitize(person?.person?.file_selfie?.url)">
            </div>




            <div *ngIf="!loading && person?.person" class="text-center mb-5">
                <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                <div> {{getIdentity(person)}}</div>
                <div> {{getContact(person)}}</div>
                <div> {{getEmailContact(person)}}</div>

                <div class="pt-2 fw-bold" *ngIf="person?.resumen?.dias_morosidad_sin_historico > 0"> <span class="fas fa-info-circle text-warning  fz-20"></span> Cliente con morosidad de {{person?.resumen?.dias_morosidad_sin_historico}} dias</div>
                <span class="pt-4 " *ngIf="person?.resumen?.dias_morosidad_sin_historico <= 0"> <span class="fas fa-check-circle text-success  fz-20"></span> <span class="fw-bold"> Cliente al dia con sus pagos</span> <span *ngIf="person?.resumen?.dias_morosidad>0"><br>(Con una morosidad máxima de {{ person?.resumen?.dias_morosidad }} días) </span></span>

                <div class="pt-2" *ngIf="person?.person?.direct_debit_afiliation"><span class="fas fa-check-circle text-success  fz-20"></span> {{ 'Domiciliado ' }} <span class="font-weight-bold">{{ person?.person?.direct_debit_afiliation?.bank?.name }}</span></div>

        </div>



            <ng-container *ngIf="!loading && person?.person" class="mt-2">


                <div class="mt-md-3 p-3">


                    <div class="row ">
                        <div class=" col-12 col-md-8 offset-md-2  p-0 ">
                            <div *ngIf="!clientAprov " class="alert alert-secondary  p-2 mb-3" role="alert">
                                <span>Por favor complete el Onboarding del cliente</span>
                            </div>
                            <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">

                                <div class="row">

                                    <div class="d-grid gap-2  col-12 col-md-6 mx-auto mt-2">

                                        <div class="d-flex w-100 mt-2 mb-2" *ngIf="direct_debit_customer.length>0">
                                            <div>
                                                <i class="fas fa-exclamation-triangle text-danger"></i> El cliente posee un cobro por domiciliación en proceso, por favor no realice ningún pago para evitar duplicidad de cobros.

                                            </div>
                                        </div>


                                        <button type="button" class="btn btn-outline-primary mb-3" [routerLink]="['/console/person/onboarding/',person?.person?.code]">Onboarding</button>
                                        <button type="button" class="btn btn-outline-primary mb-3" [routerLink]="['/console/person/plans/',person?.person?.code]">Consultar planes</button>
                                        <button [disabled]="loadQuota" type="button" class="btn btn-outline-primary mb-3" (click)="pocisionBottom(pocision)">Consultar cupo <span *ngIf="loadQuota">...</span></button>



                                        <!-- <ng-container *ngIf="clientAprov && clientAprovBuro && !loading_buro"> -->

                                        <ng-container>
                                            <button type="button" class="btn btn-outline-primary mb-3" [routerLink]="['/console/person/financial-payments/',person?.person?.code]">Compras financiadas </button>
                                            <button type="button" class="btn btn-outline-primary  mb-3" [routerLink]="['/console/payments/person/',person?.person?.code]">Pagos registrados</button>
                                            <button type="button" class="btn btn-outline-primary  mb-3" [routerLink]="['/console/payments/person-fees/',person?.person?.code]">Penalidades</button>
                                        </ng-container>


                                        <button type="button" class="btn btn-outline-primary  mb-3"  *ngIf="countInvoice > 0 && direct_debit_customer.length===0" [routerLink]="['/console/post/person/',person?.person?.code]">Nuevo pago</button>

                                        <!-- solo se mostrará si la cuenta está en mora entre x y xx dias ;  *ngIf="direct_debit_customer.length===0 && fee_to_apply" -->


                                        <button type="button" class="btn btn-outline-danger  mb-3"  *ngIf="countInvoice === 0 && person.resumen.aplica_reactivacion && direct_debit_customer.length===0"  [routerLink]="['/console/post/person/',person?.person?.code]">Cancelar penalidad</button>


                                        <button *ngIf="_auth.IsPermitid(['OWNER'])" type="button" class="btn btn-outline-danger  mb-5" (click)="restClientBuro()">Eliminar cliente</button>


                                        <!-- <button type="button" class="btn btn-outline-primary btn-lg mb-3" [routerLink]="['/console/consult-quota']">Consultar cupo</button>
                                        <button type="button" class="btn btn-outline-primary btn-lg mb-3" [routerLink]="['/console/credit-calculator']">Calculadora</button> -->

                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>


            <div *ngIf="!loading && !person?.person">
                <div>No se encontro person con este id: {{queryParams?.code}}</div>
            </div>



            <ng-container *ngIf="loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>

        </div>
    </div>

</div>


<ng-template #payment let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                               Detalle de Pago
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">
            <div class="row">
                <div class=" col-12 col-md-10 offset-md-1">
                    <div class="text-start">
                        <app-person-payments [code_person]="params_payment['person_code']" [code_payment]="params_payment['code_payment']" (resetStatus)="response($event)"></app-person-payments>

                    </div>
                </div>
            </div>
        </div>
    </div>
  </ng-template>
