import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeeService {
  env: any = environment;
  baseUrl;

  constructor(public httpClient: HttpClient) { 
    this.baseUrl = this.env.BASE_LA

  }

  findFeeToApply = (code: any, attribute:any, value: any) => {
    const headers = { 'content-type': 'application/json' };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/fees/fee_to_apply', {
          headers: headers,
          params: {
            code, attribute, value
          },
        })
      );
    } catch (error) {
      throw(error);
    }
  }

  findExtendFee = (value: any, code_installment: any, fee: any) => {
    const headers = { 'content-type': 'application/json' };
    try {
      let params = {            
        code_installment: code_installment,
      };

      if(value) {
        params = Object.assign(params, {
          value: value
        })
      }

      if(fee) {
        params = Object.assign(params, {
          feeId: fee
        })
      }

      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/fees/fee_extend', {
          headers: headers,
          params,
        })
      );
    } catch (error) {
      throw(error);
    }
  }

  fees = (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(this.httpClient.post<any>(this.env.ANALYSIS_REPORT + '/api/creditotal/clients/fees', body, { headers: headers, params: request }));
    } catch (error) {
      throw(error);
    }
  }


  extend_list = () => {
    const headers = { 'content-type': 'application/json' };
    const params = {};
    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/fees/extend', {
          headers: headers,
          params
        })
      );
    } catch (error) {
      throw(error);
    }
  }


  calculateAmount = (item: any) => {
    const fee = item;
    if (fee.commission_type === "FIXED") {
      return fee.fixed_amount;
    } else if (fee.commission_type === "PERCENT") {
      return (fee.percentage * item.installment.amount / 100);
    } else if (fee.commission_type === "MIXED") {
      return fee.fixed_amount + (fee.percentage * item.installment.amount / 100);
    } 
    return 0; // Valor por defecto si no se cumple ninguna condición
  };



}
